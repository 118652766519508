.view {
  display: flex;
  flex: 1;
  height: 100vh;
  height: -webkit-fill-available;
}

a {
  color: black;
}

.divider {
  margin: 0 10px;
}

.impressum {
  position: fixed;
  background-color: white;
  width: 100%;
  flex-direction: column;
  align-items: flex-end;
  font-size: 1rem;
  overflow-y: hidden
}

.impressum h1 {
  font-size: 0.75rem;
  font-family: 'FLURORegular', Helvetica, Arial, Sans-Serif;
  font-weight: normal;
  margin: 0 0 40px 0;
  transition: all 330ms ease-in-out;
  cursor: pointer;
  letter-spacing: 0.165rem;
}

.impressum h1 a {
  text-decoration: none;
}

.impressum li {
  word-break: break-word;
}

.impressum h2,
.impressum h4 {
  font-family: 'FLUROBold', Helvetica, Arial, Sans-Serif;
}

.impressum.active h1 {
  font-size: 1rem;
}
.impressum h1 .activeitem {
  text-decoration: underline;
}

.impressum > div {
  width: 90%;
  display: flex;
  overflow-y: hidden;
  flex-direction: column;
}
.impressum > div > div {
  height: 100%;
  overflow: scroll;
  padding-right: 10%;
}

.impressum p a {
  word-break: break-word;
  width: max-content;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.App > div {
  width: 80%;
}

.name h1 {
  font-family: 'FLURORegular', Helvetica, Arial, Sans-Serif;
  font-weight: normal;
  font-size: 2.2rem;
  line-height: 1.8rem;
  letter-spacing: 0.25rem;
  margin-bottom: 20px;
}

#logo {
  position: fixed;
  transition: all 520ms;
  display: block;
  right: 5%;
  top: 5vw;
  width: 50px;
  z-index: 10;
  transform-origin: bottom;
  transform: rotate(90deg);
  cursor: pointer;
}

.bold {
  position: relative;
  font-family: 'FLUROBold', Helvetica, Arial, Sans-Serif;
}

.address {
  justify-content: flex-start;
}

.address p {
  display: block;
  margin: 0;
  font-size: 0.825rem;
  /* letter-spacing: 0.125rem; */
}
.address a {
  display: block;
  margin: 0;
  font-size: 0.825rem;
  /* letter-spacing: 0.125rem; */
}

.referenzen p {
  display: block;
  margin: 0;
  font-size: 0.825rem;
}

.referenzen a {
  display: block;
  margin: 0;
  font-size: 0.825rem;
  width: max-content;
}

.referenzen p a {
  display: inline;
}

.referenzen p a:first-of-type {
  margin-left: 3px;
}

.referenzen p.top5 {
  margin-top: 10px;
}

.top5 {
  margin-top: 10px;
}

.construction {
  color: #7f7f7f;
  margin-bottom: 8px;
  font-size: 1rem;
  letter-spacing: 0.125rem;
  text-transform: uppercase;
}

.mover {
  display: inline-block;
}

h1 {
  margin: 0;
}

.top20 {
  margin-top: 20px;
}

.linkicon {
  width: 9px;
  height: 9px;
  vertical-align: top;
  margin: 4px 6px 0 3px;
}

.socials {
  display: inline-block;
}

.socials a {
  display: inline-block;
  padding: 10px 0;
}

.socials img {
  width: 10px;
  margin-right: 10px;
  vertical-align: unset;
}

.socials img.xing {
  vertical-align: middle;
}

a {
  transition: opacity 220ms;
  opacity: 1;
}

h1 span:not(.divider):not(.socials) {
  transition: opacity 220ms;
  opacity: 1;
}

.cookie {
  transition: all 440ms;
  position: absolute;
  left: 0;
  right: 0;
  padding: 3px 10%;
  z-index: 2;
  background-color: lightgrey;
}

.cookie > div {
  display: flex;
  flex-direction: row;
}

.cookie p {
  font-size: 0.75rem;
  font-family: 'FLURORegular', Helvetica, Arial, Sans-Serif;
  margin: 0;
}

.cookie p span {
  font-family: 'FLUROBold', Helvetica, Arial, Sans-Serif;
  cursor: pointer;
  transition: opacity 220ms;
}

.cookie p span:hover {
  opacity: 0.5;
}

.cookieBtn {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.cookieInfo {
  padding-right: 10px;
  display: flex;
  align-items: center;
}

.cookieBtn div {
  border: 1px solid black;
}

.cookieBtn div p {
  font-family: 'FLUROBold', Helvetica, Arial, Sans-Serif;
  padding: 2px;
  margin: 0;
  color: black;
}

@media only screen and (min-width: 866px) {
  .cookie p span:hover {
    opacity: 0.5;
  }
  a:hover {
    opacity: 0.5;
  }

  h1 span:not(.divider):not(.socials):hover {
    opacity: 0.5;
  }
}
